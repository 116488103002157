var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"选择砍价活动","visible":_vm.visible,"width":"80%"},on:{"update:visible":function($event){_vm.visible=$event},"ok":function($event){_vm.visible = false;
    _vm.selectedRowKeys = [];},"cancel":function($event){_vm.visible = false;
    _vm.selectedRowKeys = [];}}},[_c('div',{staticClass:"search-tool"},[_c('a-form',{attrs:{"layout":"inline","form":_vm.form},on:{"submit":_vm.handleSearch}},[_c('a-form-item',{attrs:{"label":"活动状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status']),expression:"['status']"}],staticStyle:{"width":"100px"},attrs:{"placeholder":"活动状态"},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}},[_c('a-select-option',{attrs:{"value":""}},[_vm._v("全部")]),_c('a-select-option',{attrs:{"value":"未开始"}},[_vm._v("未开始")]),_c('a-select-option',{attrs:{"value":"进行中"}},[_vm._v("进行中")]),_c('a-select-option',{attrs:{"value":"已过期"}},[_vm._v("已过期")]),_c('a-select-option',{attrs:{"value":"已失效"}},[_vm._v("已失效")])],1)],1),_c('a-form-item',{attrs:{"label":"商品标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['title']),expression:"['title']"}],attrs:{"maxLength":20,"allow-clear":"","placeholder":"输入商品名称"},model:{value:(_vm.filters.title),callback:function ($$v) {_vm.$set(_vm.filters, "title", $$v)},expression:"filters.title"}})],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 搜索 ")]),_c('a-button',{style:({ marginLeft: '8px' }),attrs:{"html-type":"reset"},on:{"click":function($event){_vm.filters = { title: '' }}}},[_vm._v(" 复位 ")])],1)],1)],1),_c('a-table',{ref:"productTable",attrs:{"rowKey":"id","columns":_vm.columns,"data-source":_vm.list,"rowSelection":{
      type: _vm.multiple ? 'checkbox' : 'radio',
      onChange: _vm.onSelectChange,
      selectedRowKeys: _vm.selectedRowKeys
    },"loading":_vm.loading,"locale":{
      emptyText: '请前往「营销互动」 -> 「砍价活动」，创建砍价活动'
    }},scopedSlots:_vm._u([{key:"img",fn:function(url){return _c('span',{},[_c('img',{staticClass:"kan-product-img",attrs:{"src":url}})])}},{key:"startTime",fn:function(time){return _c('span',{},[_vm._v(_vm._s(_vm._f("formatDate")(time)))])}},{key:"endTime",fn:function(time){return _c('span',{},[_vm._v(_vm._s(_vm._f("formatDate")(time)))])}},{key:"createdAt",fn:function(time){return _c('span',{},[_vm._v(_vm._s(_vm._f("formatDate")(time)))])}}])}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"slot":"footer"},slot:"footer"},[_c('div'),_c('div',[(_vm.multiple)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]):_vm._e(),_c('a-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("取消")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }