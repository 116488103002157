<template>
  <Layout>
    <div class="app-help">
      <div>
        <a class="help-banner" href="/help/" target="_blank">
          <img src="@/assets/help-banner.png" />
        </a>
      </div>
      <div class="content">
        <div class="contact">
          <div class="col">
            <div class="title">商家装修运营交流</div>
            <img src="@/assets/qy_qrcode.png" />
            <div class="tips">微信扫码进群</div>
          </div>
          <div class="col">
            <div class="title">小商店装修推广技巧</div>
            <img src="@/assets/gzh_qrcode.png" />
            <div class="tips">微信扫码关注</div>
          </div>
          <div class="col">
            <div class="title">客服在线</div>
            <img src="@/assets/kf.png" />
            <div class="tips">扫码添加客服微信<br />周一至周五 9:00-21:00</div>
          </div>
        </div>
        <div class="faq">
          <h2>常见问题</h2>

          <div class="list">
            <div class="col">
              <h3>Q：我是小程序标准交易组件，可以使用装修服务吗？</h3>
              <p>
                1、可以，您需要先完成小程序授权，然后按步骤上传代码，等待微信官方审核通过
              </p>
              <p>2、小程序正式上线后，就可以正常使用装修服务了。</p>
              <p>
                <router-link :to="{ name: 'Code' }" target="_blank">
                  <a-icon type="qrcode" /> 点击这里开始授权流程
                </router-link>
              </p>
            </div>
            <div class="col">
              <h3>Q：为什么点击发布上线后，我的小商店内没有生效？</h3>
              <p>
                1、首先确认您发布上线时，在手机微信内收到了启用通知（企业店铺会发到店长微信上）
              </p>

              <p>2、启用通知会发到微信聊天列表中：服务通知-装修模板启用</p>
              <p>3、进入启用通知后会看到「确认启用」按钮，需要您点击确认下</p>
              <p>4、启用成功时会提示在「30 分钟内生效」</p>
            </div>
            <div class="col">
              <h3>Q：点击启用通知时提示「启用失败」怎么办？</h3>
              <p>
                1、启用通知需要再 30 分钟点击才会有效，启用失败是由于消息过期了
              </p>
              <p>
                2、您在此页面顶部点击「停用服务」后再点击「启用服务」，会重新收到启用通知
              </p>
            </div>
            <div class="col">
              <h3>Q：试用版可以正常发布上线吗？</h3>
              <p>
                1、试用版可正常使用，功能点当前与专业版一致。试用满意可购买专业版，会更加稳定
              </p>
            </div>
            <div class="col">
              <h3>Q：服务到期后会发生什么？</h3>
              <p>
                1、服务到期后会自动返回为小商店默认样式，建议提前购买服务时长
              </p>
              <p>
                2、{{
                  title
                }}后台为您保留了现有配置，服务再次购买后点击发布可一键发布上线
              </p>
            </div>
            <div class="col">
              <h3>Q：我需要开具发票，该怎么申请？</h3>
              <p>1、可以开具发票，请扫描二维码联系客服申请即可。</p>
            </div>
          </div>
        </div>

        <!-- 绑定用户 -->
        <div @click="openQRCode" class="bindBtn">
          <img src="@/assets/contact/ic-bind@3x.png" />
          <div>绑定微信</div>
        </div>

        <a-modal
          :visible="showQRCode"
          @ok="showQRCode = false"
          @cancel="showQRCode = false"
          :footer="null"
          :bodyStyle="{
            'text-align': 'center',
          }"
        >
          <vue-qrcode :width="200" :value="qrcode" />
          <div>微信扫码</div>
        </a-modal>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import VueQrcode from "vue-qrcode";

const UserSvc = require("@/service/user");

export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE,

      showQRCode: false,
      qrcode: "",
    };
  },

  methods: {
    async openQRCode() {
      try {
        const { url } = await UserSvc.getBindUrl();
        this.qrcode = url;

        this.showQRCode = true;
      } catch (err) {
        console.error(err);
      }
    },
  },

  components: {
    Layout,
    VueQrcode,
  },
};
</script>

<style lang="scss">
.app-help {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .adv {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .col {
      &.exlink {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .help-banner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .content {
    background: #fff;
    padding: 24px 24px;

    .contact {
      display: flex;
      justify-content: space-around;
      margin: 10px;

      .col {
        text-align: center;

        .title {
          font-size: 20px;
          font-weight: 600;
          color: rgb(0, 130, 239);
        }

        img {
          width: 300px;
        }

        .tips {
          color: #999;
        }
      }
    }

    .faq {
      margin: 20px 50px;

      .list {
        display: flex;
        flex-wrap: wrap;

        .col {
          width: calc(50% - 20px);
          margin: 10px;
        }
      }
    }
  }

  .bindBtn {
    position: fixed;
    right: 50px;
    bottom: 200px;
    font-size: 11px;
    text-align: center;
    cursor: pointer;

    padding: 10px;
    background: #fff;
    color: #57bb2f;
    border-radius: 12px;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      transform: scale(0.9);
      transition: 0.15s;
    }

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }
  }
}
</style>
